<template>
  <div
    :class="[
      'ui-input',
      {
        'is-error': error,
        'is-borderless': borderless,
        'is-textarea': textarea,
        'has-prefix': !!prefix,
        'has-toggle': canToggle,
      },
    ]"
  >
    <template v-if="prefix">
      <span class="ui-input__prefix">{{ prefix }}</span>
    </template>
    <template v-if="textarea">
      <textarea
        v-model="internalValue"
        class="ui-input__input"
        :placeholder="placeholderText"
        :required="required"
        v-bind="$attrs"
      />
    </template>
    <template v-else>
      <input
        v-model="internalValue"
        class="ui-input__input"
        :type="inputType"
        :placeholder="placeholderText"
        :required="required"
        v-bind="$attrs"
      />
    </template>
    <template v-if="canToggle">
      <button
        ref="toggler"
        type="button"
        class="ui-input__toggler"
        role="presentation"
        @click="toggle"
      >
        <icon-password-visible v-if="toggled" />
        <icon-password-hidden v-else />
      </button>
    </template>
    <template v-else>
      <span
        v-if="!!$slots.icon"
        ref="inputIcon"
        class="ui-input__icon"
        role="presentation"
      >
        <slot name="icon" />
      </span>
    </template>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, type PropType, ref, toRefs, watch } from 'vue';
import IconPasswordVisible from '@/components/global/elements/IconPasswordVisible.vue';
import IconPasswordHidden from '@/components/global/elements/IconPasswordHidden.vue';

const props = defineProps({
  modelValue: { type: [String, Number] as PropType<string | number>, default: '' },
  modelModifiers: { type: Object, default: () => ({}) },
  borderless: { type: Boolean, default: false },
  type: { type: String, default: 'text' },
  prefix: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  required: { type: Boolean, default: false },
  canToggle: { type: Boolean, default: false },
  textarea: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue']);
const { modelValue, type } = toRefs(props);
const toggled = ref(false);
const internalValue = ref(modelValue.value);

const inputType = computed(() => {
  if (props.textarea) {
    return undefined;
  }
  if (type.value === 'password' && toggled.value) {
    return 'text';
  }
  return type.value;
});
const placeholderText = computed(() => (props.required ? `${props.placeholder} *` : props.placeholder));

watch(internalValue, (val) => emit('update:modelValue', val));
watch(modelValue, (val) => (internalValue.value = val));

const toggle = () => (toggled.value = !toggled.value);
</script>

<style lang="scss" scoped>
@use '@/assets/mixins/__index' as mix;

.ui-input {
  position: relative;

  &__icon,
  &__toggler {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      width: rem(80);
    }
  }

  &__prefix {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__prefix,
  &__icon {
    pointer-events: none;
  }

  &__toggler {
    @include button-reset;
  }

  &__input {
    @include mix.no-outline;
    width: 100%;
    color: black;

    &:disabled {
      cursor: not-allowed;
    }

    &::placeholder {
      color: var(--lightgreen);
    }

    .is-tel & {
      padding-left: rem(40);
    }
  }

  &.has-prefix & {
    &__input {
      padding-left: rem(40);
    }
  }

  &.is-borderless & {
    &__input {
      border-width: 0;
    }
  }

  &.has-pointer &__input,
  &__input.has-pointer {
    cursor: pointer;
  }

  &.is-textarea & {
    &__input {
      display: block;
      padding-top: rem(16);
      height: rem(150);
      resize: none;
    }
  }
}
</style>
